
<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img :src="absoluteUrl('/images/glass-lines-loading.gif')" alt="loading..."/>
            <h5 class="sm:mx-0 mx-4 my-3 d-theme-heading-color">Authenticating ...!</h5>
            <p class="text-center mb-3">You will be redirected shortly</p>
        </div>
    </div>
</template>
<script>

export default {
    computed:{
        fallbackUrl(){
           return this.absoluteUrl(`/login?intended_url=${location.origin}${this.$route.query.intended_url}`)
        }
    },
    async created(){
        if(!this.$route.query.token){
          location.replace(this.fallbackUrl);return
        }
        let token = JSON.parse(atob(this.$route.query.token));
        if(typeof token !== 'object' && token === null){
          location.replace(this.fallbackUrl);return
        }
        let formData = new FormData();
        formData.append('data', JSON.stringify(token));
        this.$http.post(this.absoluteUrl('/api/auto-login'), formData)
        .then((response) => {
            if(response.data.success){
                this.$store.commit("SET_AUTH_TOKEN", response.data.data)
                location = `${this.$route.query.intended_url}`;
            }else{
                location.replace(this.fallbackUrl)
            }
        })
    }
}
</script>
